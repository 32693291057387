<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <div class="item-name-div">名称：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:60" v-slot="{ errors }">
              <label><input class="video-name-input" name="title" type="text" v-model="pinInfo.name" maxlength="60" placeholder="请输入名称"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">讲师：</div>
          <div class="item-content-div">
            <my-select class="lecturer-select" :load-more="true" :action="lecturerNameAction" :change="onLecturerChanged" placeholder-txt="请选择讲师"/>
            <label v-if="lessonId != null">{{`(${lecturer})`}}</label>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">简介：</div>
          <div class="item-content-div">
            <validation-provider rules="max:200" v-slot="{ errors }" name="introduce">
              <label><textarea v-model="pinInfo.memo" maxlength="200" placeholder="请输入课程简介"></textarea></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">亮点：</div>
          <div class="item-content-div">
            <validation-provider rules="max:200" v-slot="{ errors }" name="introduce">
              <label><textarea v-model="pinInfo.feature" maxlength="200" placeholder="请输入课程亮点"></textarea></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">价格：</div>
          <div class="item-content-div">
            <validation-provider rules="required|amount" v-slot="{ errors }">
              <label><input class="price-input" name="price" type="text" v-model="pinInfo.price" placeholder="请输入价格"> 元</label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">课时：</div>
          <div class="item-content-div">
            <validation-provider rules="required|num" v-slot="{ errors }">
              <label><input name="num" type="text" v-model="pinInfo.num" placeholder="请输入课时"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">标签：</div>
          <div class="item-content-div">
            <div class="tag-div" v-for="(item, index) in pinInfo.tags" :key="item.id">
              <label>{{item.name}}</label>
              <img src="../../assets/del.png" alt="" @click="onDelTag(index)">
            </div>
            <my-select class="tag-select" :load-more="true" :action="tagAction" :change="onTagChanged" placeholder-txt="请选择标签"/>
          </div>
        </div>
        <div class="operate-div">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <div class="text-c">
            <input type="button" value="取消" @click="onCancel">
            <input class="save-input" type="button" value="保存" @click="onSave">
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import MySelect from "../../components/MySelect"
import { getLecturerNameItems, getRecruitTags3, getLessonInfo, postLessonAdd, postLessonEdit } from "@/common/api";

export default {
  components: { MySelect },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    topicId: {
      type: String,
      default: ""
    },
    lessonId: {
      type: String,
      default: null
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      lecturerNameAction: getLecturerNameItems,
      tagAction: getRecruitTags3,
      pinInfo: {
        name: "",
        memo: "",
        feature: "",
        num: 0,
        price: 0,
        lecturerId: "",
        tags: []
      },
      lecturer: "",
      delTags: [],
      tipMsg: ""
    }
  },
  created() {
    if(this.lessonId != null){
      getLessonInfo({id: this.lessonId})
        .then(data => {
          this.pinInfo.name = data.name;
          this.pinInfo.memo = data.memo;
          this.pinInfo.feature = data.feature;
          this.pinInfo.num = data.num;
          this.pinInfo.price = data.price / 100;
          this.pinInfo.tags = data.tags;
          this.lecturer = data.lecturer;
        })
        .catch(error => {
          this.$layer.close(this.layerid);
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询课时信息失败，请稍后重试");
          }
        });
    }
  },
  methods: {
    onLecturerChanged(item) {
      this.pinInfo.lecturerId = item.id
    },
    onTagChanged(item) {
      for(let index in this.pinInfo.tags){
        if(this.pinInfo.tags[index].id === item.id) {
          return;
        }
      }
      this.pinInfo.tags.push(item);
    },
    onDelTag(index) {
      let itemArr = this.pinInfo.tags.splice(index, 1)
      this.delTags.push(itemArr[0].id)
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      let formData = new FormData();
      formData.append("name", this.pinInfo.name);
      formData.append("lecturerId", this.pinInfo.lecturerId);
      formData.append("memo", this.pinInfo.memo);
      formData.append("feature", this.pinInfo.feature);
      formData.append("price", `${this.pinInfo.price * 100}`);
      formData.append("num", this.pinInfo.num);
      this.pinInfo.tags.forEach((v, k) => {
        formData.append(`tags[${k}]`, v.id);
      })
      if(this.lessonId != null){
        formData.append("id", this.lessonId);
        this.delTags.forEach((v, k) => {
          formData.append(`delTags[${k}]`, v);
        })
      }

      this.$refs.form.validate().then(res => {
        if(res){
          if(this.lessonId == null){
            this.addLesson(formData)
          }else{
            this.editLesson(formData);
          }
        }
      });
    },
    addLesson(formData) {
      postLessonAdd(formData)
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    editLesson(formData) {
      postLessonEdit(formData)
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .item-name-div {
        width: 70px;
        text-align: right;
      }
      .item-content-div {
        flex: 1;
        display: flex;
        align-items: center;
        input {
          width: 108px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        textarea {
          width: 302px;
          height: 62px;
          resize: none;
          padding: 3px;
          border: 1px solid #dcdcdc;
        }
        .lecturer-select {
          width: 230px;
          height: 33px;
          margin-right: 5px;
        }
        .tag-select {
          width: 120px;
          height: 33px;
        }
        .tag-div {
          padding: 5px 8px;
          display: flex;
          position: relative;
          label {
            margin-right: 5px;
            padding: 0 3px;
            border: 1px solid #dcdcdc;
            border-radius: 3px;
          }
          img {
            width: 16px;
            height: 16px;
            position: absolute;
            top: -3px;
            right: 3px;
          }
        }
        .video-name-input {
          width: 218px;
        }
        .price-input {
          width: 198px;
        }
        .err-tips {
          margin-left: 5px;
        }
      }
    }
    .operate-div {
      margin-top: 10px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
      }
    }
  }
}
</style>