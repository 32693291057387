var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layer-page-div"},[_c('div',{staticClass:"layer-content-div"},[_c('validation-observer',{ref:"form"},[_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("名称：")]),_c('div',{staticClass:"item-content-div"},[_c('validation-provider',{attrs:{"rules":"required|max:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pinInfo.name),expression:"pinInfo.name"}],staticClass:"video-name-input",attrs:{"name":"title","type":"text","maxlength":"60","placeholder":"请输入名称"},domProps:{"value":(_vm.pinInfo.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pinInfo, "name", $event.target.value)}}})]),_c('label',{staticClass:"err-tips"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("讲师：")]),_c('div',{staticClass:"item-content-div"},[_c('my-select',{staticClass:"lecturer-select",attrs:{"load-more":true,"action":_vm.lecturerNameAction,"change":_vm.onLecturerChanged,"placeholder-txt":"请选择讲师"}}),(_vm.lessonId != null)?_c('label',[_vm._v(_vm._s(("(" + _vm.lecturer + ")")))]):_vm._e()],1)]),_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("简介：")]),_c('div',{staticClass:"item-content-div"},[_c('validation-provider',{attrs:{"rules":"max:200","name":"introduce"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.pinInfo.memo),expression:"pinInfo.memo"}],attrs:{"maxlength":"200","placeholder":"请输入课程简介"},domProps:{"value":(_vm.pinInfo.memo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pinInfo, "memo", $event.target.value)}}})]),_c('label',{staticClass:"err-tips"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("亮点：")]),_c('div',{staticClass:"item-content-div"},[_c('validation-provider',{attrs:{"rules":"max:200","name":"introduce"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.pinInfo.feature),expression:"pinInfo.feature"}],attrs:{"maxlength":"200","placeholder":"请输入课程亮点"},domProps:{"value":(_vm.pinInfo.feature)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pinInfo, "feature", $event.target.value)}}})]),_c('label',{staticClass:"err-tips"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("价格：")]),_c('div',{staticClass:"item-content-div"},[_c('validation-provider',{attrs:{"rules":"required|amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pinInfo.price),expression:"pinInfo.price"}],staticClass:"price-input",attrs:{"name":"price","type":"text","placeholder":"请输入价格"},domProps:{"value":(_vm.pinInfo.price)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pinInfo, "price", $event.target.value)}}}),_vm._v(" 元")]),_c('label',{staticClass:"err-tips"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("课时：")]),_c('div',{staticClass:"item-content-div"},[_c('validation-provider',{attrs:{"rules":"required|num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pinInfo.num),expression:"pinInfo.num"}],attrs:{"name":"num","type":"text","placeholder":"请输入课时"},domProps:{"value":(_vm.pinInfo.num)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pinInfo, "num", $event.target.value)}}})]),_c('label',{staticClass:"err-tips"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"item-name-div"},[_vm._v("标签：")]),_c('div',{staticClass:"item-content-div"},[_vm._l((_vm.pinInfo.tags),function(item,index){return _c('div',{key:item.id,staticClass:"tag-div"},[_c('label',[_vm._v(_vm._s(item.name))]),_c('img',{attrs:{"src":require("../../assets/del.png"),"alt":""},on:{"click":function($event){return _vm.onDelTag(index)}}})])}),_c('my-select',{staticClass:"tag-select",attrs:{"load-more":true,"action":_vm.tagAction,"change":_vm.onTagChanged,"placeholder-txt":"请选择标签"}})],2)]),_c('div',{staticClass:"operate-div"},[_c('div',{staticClass:"tips-div text-c err-tips"},[_vm._v(_vm._s(_vm.tipMsg))]),_c('div',{staticClass:"text-c"},[_c('input',{attrs:{"type":"button","value":"取消"},on:{"click":_vm.onCancel}}),_c('input',{staticClass:"save-input",attrs:{"type":"button","value":"保存"},on:{"click":_vm.onSave}})])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }